import { 
    SiJavascript, 
    SiJava, 
    SiPython, 
    SiCplusplus, 
    SiReact, 
    SiCss3, 
    SiHtml5,
    SiDocker,
    SiWordpress,
    SiAmazonaws,
    SiMarkdown,
    SiKalilinux,
} from 'react-icons/si';

const skillsIconProps = {size: 36, className: 'mb-4' }

const skillsInfo = [
    {
        name: 'JavaScript',
        icon: <SiJavascript {...skillsIconProps}/>
    },
    {
        name: 'Java',
        icon: <SiJava {...skillsIconProps}/>
    },
    {
        name: 'Python',
        icon: <SiPython {...skillsIconProps}/>
    },
    {
        name: 'C++',
        icon: <SiCplusplus {...skillsIconProps}/>
    },
    {
        name: 'Docker',
        icon: <SiDocker {...skillsIconProps}/>
    },
    {
        name: 'React',
        icon: <SiReact {...skillsIconProps}/>
    },
    {
        name: 'CSS3',
        icon: <SiCss3 {...skillsIconProps}/>
    },
    {
        name: 'HTML5',
        icon: <SiHtml5 {...skillsIconProps}/>
    },
    {
        name: 'WordPress',
        icon: <SiWordpress {...skillsIconProps}/>
    },
    {
        name: 'AWS',
        icon: <SiAmazonaws {...skillsIconProps}/>
    },
    {
        name: 'Markdown',
        icon: <SiMarkdown {...skillsIconProps}/>
    },
    {
        name: 'Kali Linux',
        icon: <SiKalilinux {...skillsIconProps}/>
    },
]

export const Skills = () => {
    return (
        <div id="skills" className="animate__animated animate__fadeIn">
            <h3 className="text-center">Skills</h3>

            <div className="block rounded-lg shadow-lg bg-white w-full mb-8">

                {/* Card content */}
                <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-4">

                    {skillsInfo.map((skillInfo: any) => {
                        return (
                            <div className="flex flex-col items-center my-4" key={skillInfo.name}>
                                {skillInfo.icon}
                                <p className="text-center">
                                    {skillInfo.name}
                                </p>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}