import { About, Skills, Projects, Passions, Contact } from 'components';

export const Home = () => {
    return (
        <>
            <About />
            <Skills />
            <Projects />
            <Passions />
            <Contact />
        </>
    )
}