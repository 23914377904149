import { Header, Footer } from 'layouts';
import {
  Home,
  PrivacyPolicy
} from 'views';
import { createBrowserRouter, Outlet } from 'react-router-dom';

const Route = () => {
 return (
      <>
      <Header/>
      <main className="mt-16 md:container mx-auto">
            <div className="w-4/5 mx-auto">
                <Outlet/>
            </div>
        </main>
      <Footer/>
      </>
    );
};

const AppRouter = createBrowserRouter(
  [
    {
      path: '/',
      element: <Route />,
      children: [
        {
            path: '',
            element: <Home />,
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicy />,
        },
        { path: '*', element: <Home /> },
      ],
    },
  ]
);

export default AppRouter;