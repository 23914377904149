import { 
    FaGuitar,
    FaTableTennis,
    FaTv,
    FaBookOpen,
    FaPlaneDeparture
} from 'react-icons/fa';

import {
    MdSportsSoccer
} from 'react-icons/md'

const passionsIconProps = {size: 42, className: 'mb-4' }

const passionsInfo = [
    {
        name: 'Rock Music',
        icon: <FaGuitar {...passionsIconProps}/>
    },
    {
        name: 'Soccer',
        icon: <MdSportsSoccer {...passionsIconProps}/>
    },
    {
        name: 'Table Tennis',
        icon: <FaTableTennis {...passionsIconProps}/>
    },
    {
        name: 'Reading',
        icon: <FaBookOpen {...passionsIconProps}/>
    },
    {
        name: 'TV Shows/Movies',
        icon: <FaTv {...passionsIconProps}/>
    },
    {
        name: 'Traveling',
        icon: <FaPlaneDeparture {...passionsIconProps}/>
    },
]

export const Passions = () => {
    return (
        <div id="passions" className="animate__animated animate__fadeIn">
            <h3 className="text-center">Passions</h3>

            <div className="block rounded-lg shadow-lg bg-white w-full">

                {/* Card content */}
                <div className="grid md:grid-cols-3 grid-cols-2 gap-4 py-4">

                    {passionsInfo.map((passionInfo: any) => {
                        return (
                            <div className="flex flex-col items-center my-4" key={passionInfo.name}>
                                {passionInfo.icon}
                                <p className="text-center">
                                    {passionInfo.name}
                                </p>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}