import Propic from 'assets/personal-pic.jpg';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';

const socialLinks = {
    'GITHUB': 'https://github.com/thelicato',
    'TWITTER': 'https://twitter.com/thelicato',
    'LINKEDIN': 'https://www.linkedin.com/in/angelo-delicato/',
}

export const About = () => {
    return (
        <div id="about" className="animate__animated animate__fadeInDown">
            <div className="block rounded-lg shadow-lg bg-white w-full mb-8">

                {/* Card content */}
                <div className="flex p-8 flex gap-8 md:flex-row flex-col">
                    <div className="flex md:basis-2/12 items-center justify-center">
                        <img src={Propic} className="rounded-full md:w-full w-3/6" alt="Profile Pic"/>
                    </div>

                    <div className="basis-10/12">
                        <h1 className="text-center">
                            Hi!<br/>
                            I'm <b>Angelo Delicato</b>
                        </h1>
                        <h2 className="text-center">
                            Penetration Tester
                        </h2>

                        <hr className="my-6"/>

                        <p>
                            Hi! I'm a <b>Penetration Tester</b> currently working at <a href="https://secforce.com">SECFORCE</a>.<br />
                            📜 Master's Degree in Computer Engineering (<i>University of Naples, Federico II</i>) <br/>
                            🖖 Incurable nerd <br />
                            🎞️ Movie/TV Show addicted
                        </p>

                    </div>
                </div>

                {/* Card footer */}
                <div className="bg-rose-600 w-full flex p-6 rounded-b-lg shadow-lg mt-4 align-center justify-center">

                        {/* GitHub */}
                        <a
                            className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-white hover:bg-gray-800 rounded-full hover:text-white transition-colors duration-300"
                            href={socialLinks.GITHUB}
                        >
                            <FaGithub size={42} className="p-1"/>
                            <span className="sr-only">Github</span>
                        </a>

                        {/* Twitter */}
                        <a
                            className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-white hover:bg-blue-500 rounded-full hover:text-white transition-colors duration-300"
                            href={socialLinks.TWITTER}
                        >
                            <FaTwitter size={42} className="p-1"/>
                            <span className="sr-only">Twitter</span>
                        </a>

                        {/* Linkedin */}
                        <a
                            className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-white hover:bg-blue-600 rounded-full hover:text-white transition-colors duration-300"
                            href={socialLinks.LINKEDIN}
                        >
                            <FaLinkedin size={42} className="p-1"/>
                            <span className="sr-only">LinkedIn</span>
                        </a>
                    
                </div>

            </div>
        </div>
    )
}